import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled, { createGlobalStyle } from 'styled-components'
import { mq } from '../helpers'
import './PageWrapper.style.css'

import { Head, MainNav } from '../components/organisms'

const GlobalStyle = createGlobalStyle`
  body {
    background-image: linear-gradient(to right, #f7971e, #ffd200);;
    margin: 0; 
  }
`

const PageWrapEl = styled.div`
  background-color: white;
  min-height: calc(100vh - 2rem);
  box-sizing: border-box;
  padding: 1rem;
  margin: 1rem;

  ${mq.sm`
    min-height: calc(100vh - 2rem);
    padding: 1rem;
    margin: 1rem;
  `};
`

const SingleProject = ({ title, data: { mdx } }) => (
  <>
    <GlobalStyle />
    <Head pageTitle={title} />

    <PageWrapEl>
      <h3>Site Title</h3>
      <MainNav />
      <MDXRenderer>{mdx.body}</MDXRenderer>
    </PageWrapEl>
  </>
)

SingleProject.displayName = 'SingleProject'

SingleProject.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any,
}

export const pageQuery = graphql`
  query SingleProject($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`

export default SingleProject
